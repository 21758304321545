// JavaScript Document

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faBars as farFaBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faTimes as farFaTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faChevronRight as farFaChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import { faCalendar as farFaCalendar } from "@fortawesome/pro-regular-svg-icons/faCalendar";
import { faHeart as farFaHeart } from "@fortawesome/pro-regular-svg-icons/faHeart";

import { faSearch as fasFaSearch } from "@fortawesome/pro-solid-svg-icons/faSearch";

import { faFacebookF as fabFaFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faInstagram as fabFaInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faYoutube as fabFaYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube";
import { faTwitter as fabFaTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";

import { faUserCircle as falFaUserCircle } from "@fortawesome/pro-light-svg-icons/faUserCircle";
import { faBook as falFaBook } from "@fortawesome/pro-light-svg-icons/faBook";
import { faTabletAlt as falFaTabletAlt } from "@fortawesome/pro-light-svg-icons/faTabletAlt";
import { faGlassesAlt as falFaGlassesAlt } from "@fortawesome/pro-light-svg-icons/faGlassesAlt";
import { faComments as falFaComments } from "@fortawesome/pro-light-svg-icons/faComments";


/**
 * Add light icons
 */
library.add(falFaUserCircle, falFaBook, falFaTabletAlt, falFaGlassesAlt, falFaComments);

/**
 * Add regular icons
 */
library.add(farFaBars, farFaTimes, farFaChevronRight, farFaCalendar, farFaHeart);

/**
 * Add brands icons
 */
library.add(fabFaFacebookF, fabFaInstagram, fabFaYoutube, fabFaTwitter);

/**
 * Add solid icons
 */
library.add(fasFaSearch);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
